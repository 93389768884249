import PropTypes from "prop-types"
import React from "react"

import Button from "@components/Button/Button"
import ButtonGroup from "@components/Button/ButtonGroup"
import TheaterVideo from "@components/Theater/TheaterVideo"

export default function ButtonGroupMap(props) {
  return (
    <ButtonGroup {...props}>
      {props.buttons &&
        props.buttons.map((buttonObj) => {
          let button = buttonObj.button
          if (button.appearance === "video") {
            return (
              <TheaterVideo
                videoUrl={`https://www.youtube.com/watch?v=${button.youtube}`}
                controls={true}
                playing={true}
                onlyButton={true}
                language={"en"}
                buttonClass="contained"
              />
            )
          } else if (props.hyperlink) {
            return (
              <a
                className={`standard-button ${button.appearance}`}
                target={button.external ? "_blank" : ""}
                key={button.href}
                href={button.href}
              >
                {button.buttonText}
              </a>
            )
          } else {
            return (
              <Button
                contained={button.appearance === "contained"}
                minimal={button.appearance === "minimal"}
                external={button.destination === "external"}
                tel={button.destination === "tel"}
                key={button.href}
                buttonText={button.buttonText}
                href={button.href}
              />
            )
          }
        })}
    </ButtonGroup>
  )
}

ButtonGroupMap.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      button: PropTypes.shape({
        buttonText: PropTypes.string,
        href: PropTypes.string,
        youtube: PropTypes.string,
        appearance: PropTypes.string.isRequired,
        destination: PropTypes.string,
      }),
    })
  ),
}
